// Grid system
.s-indent {
  @extend .hidden-xs;
  &__spacer {
    @extend .col-sm-1;
  }
  &__title {
    &:before {
      content:"";
      background-image:url("../images/congo-logo-web-colour.svg");
      background-repeat:no-repeat;
      background-size: contain;
      vertical-align: middle;
      margin-right:10px;
      width:134px;
      height:28px;
      display:inline-block;
    }
  }
  &__text {
    opacity:0;
    position:fixed;
    bottom:-1em;
    left:0;
    transform:rotate(270deg);
    transform-origin:top left;
    color:$yellow;
    @extend .caps-black-heading;
    &--absolute {
      position:absolute !important;
      left:5px !important;
      bottom:-1em !important;
    }
  }
  &__back {
    @extend .text-hide;
    background-image:url("../images/back-to-top.svg");
    background-repeat:no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin-right:10px;
    width:40px;
    height:48px;
    display:inline-block;
    transform:rotate(-270deg);
    margin-right:15px;
  }
}

.s-content {
  position:relative;
}

.main {
  @extend .col-sm-11;
}

/*.post-type-archive-product,
.tax-product-category,
.single-product,
.search,
.home {
  .s-indent {
    display:none;
  }
  .main {
    @extend .col-sm-12;
  }
}*/
