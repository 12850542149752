.s-children {
  @include for (tablet){
    margin-top:1em;
  }
  
  &__item {
    @extend .row;
    margin-bottom:$size-f;
    @include for (mobile-only){
      margin-bottom: $size-c;
    }
  }
  &__link {
    @include for(tablet) {
      display:flex;
      align-items:center;
    }
    &:hover {
      text-decoration:none;
      h4 {
        text-decoration:underline;
      }
    }
  }
  &__image {
    @extend .col-xs-12;
    @extend .col-sm-6;
    img {
      width:100%;
      height:auto;
    }
  }
  &__content {
    @extend .col-xs-12;
    @extend .col-sm-6;
    padding-top:$size-e;
    padding-bottom:$size-e;
    @include for(mobile-only){
      padding-top: $size-b;
      padding-left: $size-d;
    }
    @include for(tablet) {
      margin-left:-8em;
      background:$grey;
      padding:$size-d 0 $size-d $size-f;
    }
    @include for(desktop) {
      margin-left:-8em;
      background:$grey;
      padding:$size-e 0 $size-e $size-f;
    }
    &:after {
      content: "";
      background-image:url("../images/right-arrow.svg");
      background-repeat:no-repeat;
      background-size:contain;
      display:block;
      clear:both;
      width:40px;
      height:30px;
      margin-top:$size-b;
    }
  }
  &__heading {
    font-size:$size-f;
    line-height: 1;
    margin:0 0 0.15em;
  }
  &__text {
    color:$black;
    margin:0;
    
  }
}