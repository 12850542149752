.s-quote {
  background:$white;
  padding:1em;
  @include for(tablet) {
    padding:3em 4em;
  }
  margin-top:3em;
  &__image {
    @extend .col-xs-12;
    @extend .col-md-4;
    @extend .col-lg-3;
    text-align:center;
    img {
      max-width:100%;
      height:auto;
      margin-bottom:$size-f;
    }
  }
  &__text {
    @extend .col-xs-12;
    @extend .col-md-8;
    @extend .col-lg-9;
    color:$blue;
    @include for(tablet) {
      padding-left:50px;
    }
    blockquote {
      position:relative;
      margin:0;
      margin-bottom:$size-c;
      color:$black;
      font-style:italic;
      @include for(tablet) {
        font-size:$size-e;
      }
      &:before {
        content:"";
        background-image:url("../images/quote-marks.svg");
        background-repeat:no-repeat;
        background-size:contain;
        @include for(tablet) {
          position:absolute;
          left:-50px;
          top:-10px;
        }
        display:block;
        margin-bottom:10px;
        width:40px;
        height:40px;
      }
    }
  }
}