.s-hero {
  &--image {
    background-color:$grey;
  }
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center center;
  height:225px;
  .page-parent &,
  .post-type-archive &,
  .single-project &,
  .page-id-30 &,
  .page-id-38 &,
  .page-id-63 &,
  .parent-pageid-24 & {
    height:400px;
    @include for(tablet) {
      height:500px;
    }
  }
  .single-post &,
  .page-id-4591 & {
    display:none;
  }
  .home & {
    height:500px;
    @include for(desktop) {
      height:750px;
    }
  }
  width:100%;
  position:absolute;
  top:0;
  left:0;
  overflow:hidden;
  &:after {
    content:"";
    background:$grey;
    height:100px;
    width:110%;
    margin-left:-5%;
    position:absolute;
    bottom:-65px;
    border-top:5px solid $white;
    transform:rotate(1.5deg);
  }
  .container {
    height:100%;
    position:relative;
  }
  &__text {
    position:absolute;
    bottom:150px;
    font-size:$size-f;
    @include for(tablet) {
      font-size:$size-g;
    }
    @include for(desktop) {
      bottom:170px;
      font-size:$size-h;
    }
    left:$gutter;
    z-index:2;
    @extend .chunk-white-heading;
  }
  &__teaser {
    position:absolute;
    left:$gutter;
    height:150px;
    @include for(tablet) {
      height:140px;
      left:auto;
      width:400px;
    }
    right:$gutter;
    bottom:0;
    background:$white;
    padding-right:160px;
    padding-left:15px;
    padding-bottom:20px;
    background-image:url("../images/rah-logo.svg");
    background-size:150px auto;
    background-repeat:no-repeat;
    background-position:top 20px right 15px;
    a {
      font-size:$size-d;
      @include for(tablet) {
        font-size:$size-e;
      }
      display:block;
      font-weight:$bold;
      &:after {
        content:"";
        background-image:url("../images/right-arrow.svg");
        background-repeat:no-repeat;
        background-size:contain;
        display:block;
        width:40px;
        height:30px;
        margin-top:5px;
      }
    }
  }
}
