.s-page {
  &__feature {
    @extend .col-xs-12;
    margin-bottom:1em;
  }
  &__content {
    @extend .col-xs-12;
    @extend .col-md-7;
    h1 {
      @extend .chunk-white-heading;
    }
    h3 {
      @extend .caps-black-heading;
    }
    h4 {
      font-weight:$bold;
    }
    a {
      text-decoration:underline;
    }
    blockquote {
      margin:2.5em 2.5em 2.5em 3.5em;
      position:relative;
      font-style:italic;
      &:before {
        content:"";
        background-image:url("../images/quote-marks.svg");
        background-repeat:no-repeat;
        background-size:contain;
        position:absolute;
        left:-3.5em;
        top:0;
        width:2.5em;
        height:2.5em;
      }
    }
    ul {
      list-style:none;
      padding-left: 30px;
      li {
        // text-indent:-1em;
        text-indent:-13px;
        margin-bottom:0.3em;
        &:before { 
          content:"–"; 
          position:relative; 
          left:-1em;
          top:-0.1em;
          color:$blue;
        }
      }
    }
    ol {
      list-style:decimal-leading-zero;
      padding-left:2em;
      color:$blue;
      li {
        margin-bottom:0.3em;
        em {
          color:$black;
          font-style:normal;
        }
      }
    }
    margin-bottom:1em;
  }
  &__sidebar {
    @extend .col-xs-12;
    @extend .col-md-4;
    @extend .col-md-offset-1;
  }
  &__contact {
    @extend .col-xs-12;
    @extend .col-md-7;
  }
  &__title {
    color:$black !important;
    font-style:normal !important;
    font-weight:normal !important;
    text-transform:none !important;
    font-size:$size-g !important;
    line-height:1.15 !important;
  }
}