// Sizing
$size-h: 5.063em;
$size-g-h: 4.1em;
$size-g: 3.375em;
$size-f: 2.25em;
$size-e: 1.5em;
$size-d: 1em;
$size-c: 0.667em;
$size-b: 0.444em;
$size-a: 0.296em;

// Weighting
$extra-bold: 900;
$bold: 700;
$regular: 400;
$normal: $regular;
$light: 200;

// Typography
$font-base:100%;
$line-height-base:$size-d;

// Grid settings
$main-sm-columns:12;
$sidebar-sm-columns:4;

// Colors
$grey-darkest:#303030;
$grey-darker:#a1a1a1;
$grey-dark:#c6c6c6;
$grey:#d6d6d6;
$grey-light:#dfdfdf;
$grey-lighter:#eaeaea;
$grey-lightest:#fefefe;
$black:$grey-darkest;

$blue-light:#E0DFED;
$blue:#951B81; // Congo purple
$blue-dark:#743369; // Congo darker purple
$yellow: #312783; // Congo blue
$white:#fff;
$red:#e54848;

// Media queries breakpoints
$screen-xs:480px;
$screen-xs-min:$screen-xs;
$screen-sm:768px;
$screen-sm-min:$screen-sm;
$screen-md:992px;
$screen-md-min:$screen-md;
$screen-lg:1200px;
$screen-lg-min:$screen-lg;
$screen-xs-max:($screen-sm-min - 1) !default;
$screen-sm-max:($screen-md-min - 1) !default;
$screen-md-max:($screen-lg-min - 1) !default;

// Grid system
$grid-columns:12;
$grid-gutter-width:20px;
$gutter:$grid-gutter-width/2;

// Container sizes
$container-tablet:(720px + $grid-gutter-width);
$container-sm:$container-tablet;
$container-desktop:(940px + $grid-gutter-width);
$container-md:$container-desktop;
$container-large-desktop:(1140px + $grid-gutter-width);
$container-lg:$container-large-desktop;
