// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
  img {
    max-width: 100%;
    height: auto;
  }
}
.aligncenter {
  display: block;
  margin:$size-e;
}
.alignleft,
.alignright {
  margin-bottom:$size-d;
}
@media (min-width: $screen-sm-min) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($size-e / 2);
  }
  .alignright {
    float: right;
    margin-left: ($size-e / 2);
  }
}

// Captions
.wp-caption {
  @extend .thumbnail;
}
.wp-caption-text {
  padding: $size-c;
  text-align:center;
  color:$grey-darker;
  font-style:italic;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
