.s-contact {
  margin:3em 0;
  &__wrapper {
    background:$white;
    padding:2em;
  }
  &__heading {
    @extend .caps-black-heading;
    margin-bottom:5px;
  }
  .ninja-forms-form-wrap {
    .ninja-forms-form {
      margin-top:20px;
    }
    label {
      color:$grey-darkest;
    }
    .text-wrap,
    .textarea-wrap,
    .spam-wrap {
      .ninja-forms-field {
        @extend .s-form__input;
        background-color:$grey-lighter;
      }
    }
    .submit-wrap {
      .ninja-forms-field {
        @extend .s-form__button;
      }
    }
    .ninja-forms-req-symbol {
      display:none;
    }
    .ninja-forms-required-items {
      display:none;
    }
    .ninja-forms-field-error {
      p {
        margin-top:5px;
      }
    }
  }
}