.s-feature-product {
  background-color:$grey-light;
  margin-top:3em;
  &__content {
    @extend .col-xs-12;
    @extend .col-sm-6;
    @extend .col-md-8;
    padding:30px 30px;
    h3 {
      @extend .caps-black-heading;
      font-size:$size-e;
    }
    ul, ol {
      margin-top:2em;
    }
    li {
      @extend .caps-black-heading;
      margin-bottom:0.4em;
    }
  }
  &__item {
    @extend .col-xs-12;
    @extend .col-sm-6;
    @extend .col-md-4;
  }
  &__link {
    background-color:$white;
    padding:15px 30px;
    font-size:$size-e;
    display:block;
    img {
      width:100%;
      height:auto;
    }
    h3 {
      margin-bottom:0;
    }
  }
}