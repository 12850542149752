.s-hire {
  &__search {
    @extend .col-xs-12;
    @extend .col-md-7;
    @include for(desktop) {
      @extend .pull-right;
      margin-top:-4em;
    }
    .s-search {
      margin-top:0;
    }
    margin-bottom:20px;
  }
  &__sidebar {
    @extend .col-xs-12;
    @extend .col-md-3;
    margin-bottom:20px;
  }
  &__products {
    @extend .col-xs-12;
    @extend .col-md-9;
  }
}

.s-product-categories {
  @extend .list-unstyled;
  > .cat-item {
    border-top:1px solid $grey-darker;
    > a {
      display:block;
      @extend .all-caps;
      padding:$size-c;
      &:after {
        content:"+";
        color: $blue;
        font-weight:$bold;
        position:absolute;
        right:$size-d;
      }
    }
    &.current-cat-parent {
      > a {
        &:after {
          content:"\2013";
        }
      }
      .children {
        max-height:100em;
      }
      &.selected {
        > a {
          &:after {
            content:"+";
          }
        }
        .children {
          max-height:0;
        }
      }
    }
    &.selected {
      > a {
        &:after {
          content:"\2013";
        }
      }
      .children {
        max-height:100em;
      }
    }
  }
  .children {
    @extend .list-unstyled;
    transition:max-height 0.3s;
    max-height:0;
    overflow:hidden;
    .cat-item {
      > a {
        color:$black;
        padding:$size-a $size-c;
        display:block;
      }
      &:last-child {
        margin-bottom:10px;
      }
      &.current-cat {
        background:$white;
      }
    }
  }
}

.s-products {
  @extend .row;
  &--featured {
    margin-bottom:10px;
    .s-products {
      &__product {
        @extend .col-xs-12;
        @extend .col-sm-4;
      }
      &__link {
        background:$white;
        padding:$gutter;
        margin-bottom:$gutter;
        &--on-sale {
          .s-products__image {
            &:before {
              left:0px;
              top:-10px;
            }
          }
        }
      }
      &__image,
      &__info,
      &__prices {
        @extend .col-sm-12;
      }
      &__intro {
        display:none;
      }
      &__name {
        min-height:2.5em;
      }
    }
  }

  &--related {
    @extend .col-xs-12;
    margin:0 0 10px 0;
    .s-products {
      &__heading {
        font-size:$size-e;
      }
      &__product {
        @extend .col-xs-6;
        @extend .col-sm-4;
        @extend .col-md-3;
        @include make-lg-column(12/5);
      }
      &__link {
        background:$white;
        padding:$gutter;
        margin-bottom:$gutter * 2;
        &--on-sale {
          .s-products__image {
            &:before {
              left:0px;
              top:-10px;
            }
          }
        }
      }
      &__image,
      &__info,
      &__prices {
        @extend .col-sm-12;
      }
      &__intro {
        display:none;
      }
      &__name {
        min-height:2.5em;
        font-size:$size-d;
        margin-bottom:0;
      }
    }
    .s-price--week,
    .s-price--sale {
      display:none;
    }
    .s-price__amount {
      border-bottom:0;
    }
    .s-products__prices {
      margin-bottom:0;
      min-height: 43px;
    }
  }

  &--new-in {
    margin-bottom:40px;
    background:$grey-light;
    .s-products {
      @extend .col-xs-12;
      &__product {
        @extend .clearfix;
        &:first-of-type {
          padding-top:$gutter;
        }
      }
      &__link {
        margin-top:0;
      }
    }
    @extend .clearfix;
  }

  &__heading {
    font-weight:$regular;
    font-size:$size-f;
    @extend .col-xs-12;

  }

  &__link {
    border-bottom:1px solid rgba($white, 0.3);
    margin-bottom:$gutter;
    @extend .col-xs-12;
    &--on-sale {
      .s-products__image {
        position:relative;
        &:before {
          content:"";
          background-image:url("../images/sale-marker.svg");
          background-repeat:no-repeat;
          background-size:contain;
          width:25px;
          height:25px;
          display:inline-block;
          position:absolute;
          left:10px;
          top:0;
          z-index:1;
        }
      }
    }
  }

  &__image {
    @extend .col-xs-12;
    @extend .col-sm-4;
    @extend .col-lg-3;
    img {
      width:100%;
      height:auto;
    }
  }

  &__name {
    font-weight:$regular;
    font-size:$size-e;
    margin-top:10px;
    margin-bottom:10px;
  }

  &__intro {
    color:$grey-darkest;
  }

  &__info {
    @extend .col-xs-12;
    @extend .col-sm-6;
    @extend .col-lg-7;
  }

  &__prices {
    @extend .col-xs-12;
    @extend .col-sm-2;
    margin:$gutter 0;
    ul {
      @extend .list-unstyled;
      float:left;
    }
  }
}

.s-price {
  @extend .all-caps;
  color:$black;
  &__asterisk {
    font-weight:$light;
  }
  &:last-child {
    .s-price__amount {
      border-bottom:0px;
    }
  }
  &--sale {
    .s-price__amount {
      color:$red;

      &:after {
        display: none;
      }
    }
  }
  &__description {
    font-size:$size-c;
    font-weight:$bold;
    display:block;
    margin-top:5px;
  }
  &__amount {
    display:block;
    border-bottom:1px solid rgba($black, 0.1);
    padding-bottom:3px;
    &:before {
      content:"£";
    }
    &:after {
      content:"*";
    }
  }
}
