.s-training {
  &__teachers {
    @extend .row;
    h3 {
      @extend .caps-black-heading;
      @extend .col-xs-12;
    }
  }
  &__teacher {
    @extend .col-xs-12;
    @extend .col-sm-6;
  }
  &__courses {
    @extend .col-xs-12;
    @extend .col-md-7;
    h2 {
      @extend .caps-black-heading;
    }
  }
  &__other-courses {
    padding:0 20px;
    h3 {
      @extend .caps-black-heading;
    }
    ul {
      @extend .list-unstyled;
      li {
        margin-bottom:0.2em;
      }
    }
  }
  &__wrapper {
    background-color:$grey-light;
    padding:10px 20px;
  }
  &__how {
    @extend .col-xs-12;
    @extend .col-md-4;
    @extend .col-md-push-1;
    margin:3em 0;
    strong {
      @extend .caps-black-heading;
      color:$blue;
      font-weight:normal;
      margin-top:15px;
      margin-bottom:5px;
      display:block;
    }
    p:first-child strong {
      font-size:$size-e;
      margin-top:0;
      margin-bottom:20px;
    }
    ol {
      list-style:decimal-leading-zero;
      padding-left:2em;
      color:$yellow;
      li {
        margin-bottom:0.3em;
        em {
          color:$black;
          font-style:normal;
        }
      }
    }
  }
}

.s-teacher {
  margin-bottom:40px;
  &__image {
    @extend .col-md-6;
    @include for(handheld-only) {
      border-bottom:1px solid $white;
      margin-left: 10px;
      margin-right: 10px;
    }
    @include for(desktop) {
      border-bottom: none;
    }
    img {
      width:100%;
      height:auto;

      @include for (mobile-only) {
        width: 60%;
      }
    }
  }
  &__content {
    @extend .col-md-6;
    margin-top:10px;
    @include for(desktop) {
      margin-left:-20px;
      margin-top:30px;
    }
    @include for(large-desktop) {
      margin-left:-40px;
      margin-top:40px;
    }
  }
  &__name {
    font-size:$size-e;
    color:$yellow;
    margin-bottom:5px;
    margin-top:0;
  }
  &__job {
    h3 {
      font-size:$size-c;
      padding:0;
      @extend .caps-black-heading;
    }
  }
}