.s-header {
  position:relative;
  z-index:10;
  color:$white;
  line-height:1.15;
  .page-parent &,
  .post-type-archive &,
  .single-project &,
  .page-id-30 &,
  .page-id-38 &,
  .page-id-63 &,
  .parent-pageid-24 & {
    margin-bottom:380px;
  },
  .home & {
    margin-bottom:440px;
  }
  .post-type-archive-product &,
  .tax-product-category &,
  .search &,
  .single-product &,
  .single-post &,
  .page-id-4591 & {
    margin-bottom:0px;
  }
  body & {
    margin-bottom:110px;
  }
  @include for(mobile-only) {
    background:$blue;
    .page-parent &,
    .post-type-archive &,
    .single-project &,
    .page-id-30 &,
    .page-id-38 &,
    .parent-pageid-24 & {
      margin-bottom:370px;
    }
    .post-type-archive-product &,
    .tax-product-category &,
    .search &,
    .single-product &,
    .single-post &,
    .page-id-4591 & {
      margin-bottom:65px;
    }
    body & {
      margin-bottom:190px;
    }
  }
  @include for(desktop) {
    .home & {
      margin-bottom:630px;
    }
  }
  @include for(tablet) {
    margin-top:$size-g;
  }
  a {
    color:$white;
    &.s-header__telephone {
      color:$white;
      font-weight:$light;
      span {
        letter-spacing:0.05em;
        margin-left:0.25em;
      }
    }
    &:hover {
      text-decoration:none;
    }
  }

  &__brand {
    background-image:url("../images/congo-logo-web-white.svg");
    background-repeat:no-repeat;
    // background-color:$blue;
    background-size:auto 60%;
    @include for(mobile-only) {
      width:7.5em;
      height:100%;
      position:absolute;
      // left:-$gutter;
      left: 0;
      background-position:center center;
    }
    @include for(tablet) {
      float:left;
      width:8.8em;
      margin:0.8em $gutter;
      height:1.8em;
      background-size:auto 100%;
      background-position:center left;
    }
    @include for(large-desktop) {
      width:10em;
      margin:0.7em $gutter;
      height:2em;
    }
  }

  &__banner {
    position:fixed;
    left: 0;
    float:left;
    width:100%;
    background-color:$blue;
    @include gradient-bg;
    @include for(tablet) {
      position:relative;
    }
  }

  &__text {
    @extend .visible-xs;
    color:$blue;
    padding:0.5em 0 0.5em 6em;
    margin:0;
    text-align:center;
  }

  &__menu {
    @extend .visible-xs;
    @extend .all-caps-tight;
    position:absolute;
    right:-6em;
    width:6em;
    background:rgba($white, 0.9);
    color:$blue!important;
    padding-left:2.1em !important;
    transition:all .3s ease-in-out;
    border-left:1px solid transparent;
    &:hover, .s-header__primary--open & {
      text-decoration:none;
      background:rgba($white, 1);
    }
    .s-header__primary--open & {
      .s-header__toggle {
        transform:rotate(45deg);
      }
    }
  }

  &__toggle {
    width:0.8em;
    height:0.8em;
    display:inline-block;
    position:absolute;
    left:$gutter;
    top:0.75em;
    transition:all .3s ease-in-out;
    &:before, &:after {
      content:"";
      background:$blue;
      width:100%;
      height:0.1em;
      display:inline-block;
      position:absolute;
      top:0%;
      margin-top:50%;
      left:0;
    }
    &:after {
      transform:rotate(90deg);
    }
  }

  &__primary {
    @include for(mobile-only) {
      background:$blue;
      position:absolute;
      // left:-(225px + $gutter);
      left: -225px;
      width:225px;
      transition:all .3s ease-in-out;
      &--open {
        // left:-$gutter;
        left:0;
      }
      > .nav {
        border-top:1px solid rgba(lighten($blue, 50%), 0.1);
        > .menu-item {
          > a {
            border-bottom:1px solid rgba(lighten($blue, 50%), 0.1);
            display:block;
          }
        }
      }
      a {
        padding:$gutter;
      }
    }

    > .nav {
      > .menu-item {
        position:relative;
        &:hover, &.active {
          // background-color:$blue-dark;
          background-color:$blue;
        }
        .search & {
          &.menu-hire {
            @extend .active;
          }
        }
        &.menu-hire {
          &:after {
            background-image:none;
          }
        }
        @include for(tablet) {
          &:after {
            content:"";
            background-image:url("../images/nav-marker.svg");
            background-repeat:no-repeat;
            background-size:contain;
            width:10px;
            height:10px;
            position:absolute;
            bottom:0.7em;
            right:0.7em;
            transform:rotate(180deg);
          }
          border-left:1px solid rgba(lighten($blue, 50%), 0.1);
          height:$size-g;
          width:9.5em;
          display:flex;
          position:relative;
          &.menu-technology {
            width: 10.75em;
          }
          &.menu-hire {
            width:6em;
          }
          &.menu-buy-or-install {
            width:8em;
          }
        }
        @include for(desktop) {
          width:12em;
          &.menu-technology {
            width: 12em;
          }
          &.menu-hire {
            width:12em;
          }
          &.menu-buy-or-install {
            width:10em;
          }
        }
        > a {
          @extend .all-caps-loose;
          @include for(tablet) {
            padding-left:$size-c;
            padding-right:$size-f;
            display:flex;
            align-items:flex-end;
            padding-bottom:0.5em;
            width:100%;
          }
          @include for(desktop) {
            padding-left:$size-d;
          }
        }
      }
    }

    .nav {
      @include for(tablet) {
        float:right;
      }
      @extend .list-unstyled;
      position:relative;
      .menu-item {
        font-weight:$light;
        @include for(tablet) {
          float:left;
          &:hover {
            .sub-menu {
              display:block;
            }
          }
        }
      }
      .sub-menu {
        @extend .list-unstyled;
        display:none;
        position:absolute;
        top:$size-g;
        // background-color:$blue-dark;
        background-color:$blue;
        left:0;
        padding:$size-b 0;
        .menu-item {
          float:none;
          height:auto;
          a {
            padding:$size-a $size-d;
            display:inline-block;
            width:15em;
            &:hover {
              background-color:darken($blue-dark, 5%);
            }
          }
        }
      }
    }
  }

  &__secondary {
    .menu-item {
      &.menu-learn-with-us {
        .single-course &,
        .single-post & {
          @extend .active;
        }
      }
      &.active {
        a {
          background-color:$blue-dark !important;
          color:$white !important;
        }
      }
    }
    @include for(mobile-only) {
      padding:1em 0;
      a {
        padding:0.5em $gutter;
        display:block;
      }
    }
    @include for(tablet) {
      position:absolute;
      top:- ($size-g - ($size-g - $size-f) / 2);
      right:$gutter;
      width:100%;
      .nav {
        background:rgba($white, 0.9);
        .menu-item {
          font-weight:$regular;
          &:last-child {
            a {
              border-right:0px;
            }
          }
          a {
            display:inline-block;
            line-height:$size-f;
            color:$blue;
            border-right:1px solid $blue-light;
            width:9.5em;
            padding-left:$size-d;
            &:hover {
              background:$white;
            }
          }
        }
      }
    }
  }

}
