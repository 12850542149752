.s-brands {
  background:$white;
  padding:2em;
  @include for(tablet) {
    padding:3em 4em;
  }
  margin-top:3em;
  &__text {
    h2 {
      margin:0;
    }
    ul {
      @extend .row;
      @extend .list-inline;
      li {
        @extend .col-xs-6;
        @extend .col-sm-3;
        text-align:center;
      }
    }
    em {
      font-size:$size-e;
      a {
        &:after {
          content:"";
          display:inline-block;
          background-image:url("../images/right-arrow.svg");
          background-repeat:no-repeat;
          background-size:contain;
          width:45px;
          height:40px;
          vertical-align:middle;
          margin-left:10px;
        }
      }
    }
  }
}