.page-id-342,
.parent-pageid-342 {
  background-color:$white;
  .s-hero {
    &:after {
      background:$white;
    }
  }
  .s-forward {
    background:$grey;
  }
  .s-paths {
    margin-top:0;
    border-top:0px;
  }
  .s-footer {
    margin-top:0;
    padding-top:3em;
    background:$grey;
  }
  .s-page__content {
    ul {
      @extend .row;
      @extend .list-inline;
      li {
        @extend .col-xs-6;
        @extend .col-sm-3;
        text-align:center;
        &:before {
          content:"";
        }
      }
    }
  }
}

.s-related-products {
  margin:3em 0;
  @extend .col-xs-12;
  @extend .col-sm-7;
  &__heading {
    @extend .caps-black-heading;
    margin-bottom:$size-e;
  }
  &__list {
    @extend .list-unstyled;
  }
  &__item {
    @extend .row;
    margin-bottom:$size-e;
    padding-bottom:$size-e;
    border-bottom:1px solid $grey-lighter;
  }
  &__link {
    &:hover {
      text-decoration:none;
      h4 {
        text-decoration:underline;
      }
    }
  }
  &__image {
    @extend .col-xs-12;
    @extend .col-sm-4;
    img {
      width:100%;
      height:auto;
    }
  }
  &__content {
    @extend .col-xs-12;
    @extend .col-sm-8;
  }
  &__name {
    font-size:$size-e;
    margin:0 0 $size-a;
  }
  &__text {
    color:$black;
    margin:0;
  }
  &__more {
    font-size:$size-e;
    a {
      &:after {
        content:"";
        display:inline-block;
        background-image:url("../images/right-arrow.svg");
        background-repeat:no-repeat;
        background-size:contain;
        width:45px;
        height:40px;
        vertical-align:middle;
        margin-left:10px;
      }
    }
  }
}