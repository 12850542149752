.pagination {
  text-align:center;
  margin-top:$size-g;
  .nav-links {
    font-size:$size-e;
    font-style:italic;
    .page-numbers {
      line-height:$size-e;
      width:$size-e;
      display:inline-block;
      @include for(mobile-only) {
        display:none;
      }
    }
    .current {
      background:$white;
      line-height:$size-e;
      width:$size-e;
      display:inline-block;
      @include for(mobile-only) {
        display:inline-block;
      }
    }
    .prev, .next {
      color: transparent;
      background-image:url("../images/right-arrow.svg");
      background-repeat:no-repeat;
      background-size:contain;
      width:45px;
      height:40px;
      @include for(mobile-only) {
        display:inline-block;
      }
    }
    .prev {
      background-image:url("../images/left-arrow.svg");
    }
  }
}

.s-pagination {
  font-size:$size-e;
  font-style:italic;
  margin-top:30px;
  &__next {
    @extend .pull-right;
    a {
      &:after {
        content:"";
        display:inline-block;
        background-image:url("../images/right-arrow.svg");
        background-repeat:no-repeat;
        background-size:contain;
        width:45px;
        height:40px;
        vertical-align:middle;
        margin-left:10px;
      }
    }
  }
  &__previous {
    @extend .pull-left;
    a {
      &:before {
        content:"";
        display:inline-block;
        background-image:url("../images/left-arrow.svg");
        background-repeat:no-repeat;
        background-size:contain;
        width:45px;
        height:40px;
        vertical-align:middle;
        margin-right:10px;
      }
    }
  }
}