.s-services {
  margin-top: $size-f;
  &__heading {
    @extend .caps-black-heading;
  }
  &__list {
    @extend .list-unstyled;
  }
  &__item {
    @extend .row;
    margin-bottom:$size-e;
  }
  &__image {
    @extend .col-xs-12;
    @extend .col-sm-3;
    img {
      width:100%;
      height:auto;
    }
  }
  &__text {
    @extend .col-xs-12;
    @extend .col-sm-7;
    ul {
      list-style:none;
      padding-left: 30px;
      li {
        text-indent:-13px;
        margin-bottom:0.3em;
        &:before { 
          content:"–"; 
          position:relative; 
          left:-1em;
          top:-0.1em;
          color:$blue;
        }
      }
    }
    ol {
      list-style:decimal-leading-zero;
      padding-left:2em;
      color:$blue;
      li {
        margin-bottom:0.3em;
        em {
          color:$black;
          font-style:normal;
        }
      }
    }
  }
  &__title {
    font-size:$size-d;
    font-weight:bold;
    margin:$size-b 0;
  }
}