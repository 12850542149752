@mixin for($breakpoint) {

  @if $breakpoint == "mobile" {
    @media (min-width: 0px) {
      @content;
    }
  }

  @else if $breakpoint == "mobile-only" {
    @media (min-width: 0px) and (max-width: $screen-xs-max) {
      @content;
    }
  }

  @else if $breakpoint == "tablet" {
    @media (min-width: $screen-sm) {
      @content;
    }
  }

  @else if $breakpoint == "tablet-only" {
    @media (min-width: $screen-sm) and (max-width: $screen-md-max) {
      @content;
    }
  }

  @else if $breakpoint == "handheld-only" {
    @media (min-width: 0px) and (max-width: $screen-md-max) {
      @content;
    }
  }

  @else if $breakpoint == "desktop" {
    @media (min-width: $screen-md) {
      @content;
    }
  }

  @else if $breakpoint == "desktop-only" {
    @media (min-width: $screen-md) and (max-width: $screen-lg-max) {
      @content;
    }
  }

  @else if $breakpoint == "large-desktop" {
    @media (min-width: $screen-lg) {
      @content;
    }
  }

}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

@mixin list-unstyled {
  padding-left:0;
  list-style:none;
  margin:0;
}

.list-unstyled {
  @include list-unstyled;
}

.list-inline {
  @include list-unstyled;
  margin-left: -5px;
  > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@mixin all-caps {
  text-transform:uppercase;
  letter-spacing:0.1em;
}

.all-caps {
  @include all-caps;
}

.all-caps-loose {
  @include all-caps;
  letter-spacing:0.15em;
}

.all-caps-tight {
  @include all-caps;
  letter-spacing:0;
}

.chunk-white-heading {
  color:$white;
  font-style:italic;
  font-weight:$extra-bold;
  text-transform:uppercase;
  font-size:$size-f;
  @include for(tablet) {
    font-size:$size-g-h;
  }
  margin:0 0 0.3em 0;
  line-height:0.9em;
}

.caps-black-heading {
  color:$black;
  @extend .all-caps-loose;
  text-transform:uppercase;
  font-weight:$normal;
  font-size:$size-d;
  margin:0 0 1em 0;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.image-filter {
  display:inline-block;
  position:relative;
  &:after {
    content:"";
    background-image:url("../images/image-filter.png");
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    opacity:0.5;
  }
  &--background {
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
  }
}

.responsive-iframe {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin gradient-bg {
  background: $blue;
  background: -moz-linear-gradient(left, $blue 0%, $yellow 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, $blue), color-stop(100%, $yellow));
  background: -webkit-linear-gradient(left, $blue 0%, $yellow 100%);
  background: -o-linear-gradient(left, $blue, $yellow 100%);
  background: -ms-linear-gradient(left, $blue, $yellow 100%);
  background: linear-gradient(to right, $blue, $yellow 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#322783', endColorstr='#951b81', GradientType=1 );
}
