.s-product {
  @include for(large-desktop) {
    margin-top:$size-e;
  }

  &__category {
    display:inline-block;
    margin-bottom:$size-c;
    background-color:$grey-light;    
    padding:7px 10px;
    margin-right:5px;
    &:before {
      content:"";
      background-image:url("../images/left-arrow.svg");
      background-repeat:no-repeat;
      background-size:contain;
      width:$size-d;
      height:$size-d;
      vertical-align:middle;
      margin-right:$size-b;
      display:inline-block;
    }
  }

  &--on-sale {
    .s-product__hero {
      position:relative;
      &:before {
        content:"";
        background-image:url("../images/sale-marker.svg");
        background-repeat:no-repeat;
        background-size:contain;
        width:25px;
        height:25px;
        display:inline-block;
        position:absolute;
        left:0;
        top:0;
        z-index:1;
      }
    }
  }

  &__assets {
    @extend .col-xs-12;
    @extend .col-md-6;
    margin-bottom:20px;
  }
  &__thumbnail {
    margin-top:$gutter;
    margin-right:$gutter;
    display:inline-block;
    img {
      width:100%;
      height:auto;
    }
  }
  &__hero {
    img {
      width:100%;
      height:auto;
    }
  }
  &__downloads {
    background-color:$grey-light;
    padding:$size-e;
    margin-top:$size-f;
    h4 {
      @extend .all-caps;
      margin-top:0;
      font-weight:$regular;
    }
    ul {
      @extend .list-unstyled;
      li {
        margin-bottom:$size-a;
      }
      a {
        text-decoration:underline;
      }
    }
  }
  &__info {
    @extend .col-xs-12;
    @extend .col-md-6;
    margin-bottom:20px;
  }
  &__name {
    @extend .col-xs-12;
    font-weight:$regular;
  }
  &__prices {
    border-top:1px solid $grey-dark;
    border-bottom:1px solid $grey-dark;
    padding-top:$size-c;
    ul {
      @extend .list-inline;
    }
    li {
      margin-right:$size-e;
    }
    .s-price__amount {
      border-bottom:0px;
    }
  }
  &__content {}
  &__details {
    width:100%;
    margin-top:$size-f;
    th {
      background-color:$grey-lighter;
      text-align:left;
      @extend .all-caps;
      font-weight:$regular;
      padding:$size-c;
      border:1px solid $grey;
    }
    tbody {
      font-weight:$light;
      tr {
        background-color:$grey-light;
        &:nth-child(even){
          background-color:$grey;
        }
      }
      td {
        padding:$size-c;
        border:1px solid $grey;
        &.label {
          font-weight:$normal;
        }
      }
    }
  }
  &__contact {
    font-size:$size-e;
  }
}
