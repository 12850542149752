.s-breadcrumbs {
  @extend .hidden-xs;
  font-size:$size-c;
  text-transform:uppercase;
  font-weight:$regular;
  margin-top:$size-d;
  color:$blue;
  a {
    margin-right:$size-b;
  }
  span[rel="v:child"],
  .breadcrumb_last {
    margin-left:$size-b;
  }
  .breadcrumb_last {
    color:$black;
  }
}