body {
  &.home {
    .s-forward {
      background:$white;
      margin-top:50px;
    }
    .s-footer {
      background:$white;
      &__rah {
        span {
          background:$white;
        }
      }
    }
  }
}