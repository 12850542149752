.s-contact-us {
  h3 {
    @extend .caps-black-heading;
  }
  h4 {
    font-weight:$bold;
  }
  a {
    text-decoration:underline;
  }
  &__details {
    @extend .col-xs-12;
    @extend .col-md-5;
    margin-bottom:1em;

    h2 {
      margin-top: $size-a;
      margin-bottom: 0;
    }
    h3 {
      margin-top: $size-e;
      margin-bottom: 0;
      @include for(desktop){
        margin-top:$size-f;
      }
    }
  }
  &__map {
    @extend .col-xs-12;
    @extend .col-md-7;
    .responsive-iframe {
      border:5px solid $white;
    }
    margin-bottom:1em;
  }
}
