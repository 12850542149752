.s-listings {
  @extend .row;

  &__feature {
    @extend .col-xs-12;
  }

  &__content {
    @extend .col-xs-12;
    @extend .col-md-8;
    margin-bottom:3em;
  }

  &__sidebar {
    @extend .col-xs-12;
    @extend .col-md-3;
    @extend .col-md-push-1;
  }

  &__heading {
    @extend .col-xs-12;
    margin-top:0;
  }

  &__content {
    &--1 {
      .post-type-archive-item & {
        .s-listings__item {
          &:first-of-type {
            .s-listings__info {
              &:before {
                content:"Latest news";
                @extend .caps-black-heading;
              }
            }
            .s-listings__name {
              font-size:$size-f;
              margin-top:0;
            }
            .s-listings__intro {
              display:none;
            }
          }
        }
      }
    }
  }

  &__list {
    &--featured {
      .s-listings__image {
        @extend .col-xs-5;
        @extend .col-md-4;
      }
      .s-listings__info {
        @extend .col-xs-7;
        @extend .col-md-8;
      }
      .s-listings__name {
        @include for(tablet) {
          font-size:$size-f;
        }
        margin-top:0;
        &:before {
          content:"Featured";
          display:block;
          @extend .caps-black-heading;
          font-size:$size-b;
          margin-bottom:0.3em;
        }
      }
    }
  }
  &__item {
    border-bottom:1px solid $white;
    margin-bottom:2.5em;
    &--course {
      .s-listings__info {
        @extend .col-xs-12;
      }
    }
    &--article {
      .s-listings__info {
        margin-top:10px;
        @include for(mobile-only) {
          margin-top:0;

        }
        @include for(desktop) {

          margin-top:10px;
        }
        @include for(large-desktop) {
          
          margin-top:10px;
        }
      }
    }
  }
  &__image {
    @extend .col-xs-4;
    .image-filter, img {
      width:100%;
      height:auto;
    }
  }
  &__info {
    @extend .col-xs-8;
  }
  &__date {
    font-style:italic;
    margin-top:5px;
  }
  &__name {
    margin-top:0.3em;
    margin-bottom:0;
    font-size:$size-e;
  }
  &__categories {
    margin-top:0.1em;
    color:$black;
    font-style:italic;
    a {
      font-style:normal;
      &:after {
        content:", ";
      }
      &:last-child {
        &:after {
          display:none;
        }
      }
    }
  }
  &__intro {
    a {
      color:$black;
    }
  }
}
