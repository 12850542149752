body.single-post {
    // background-color: red;
    .s-page {
        &__title {
            line-height: 1.1!important;
            @include for (mobile-only) {
                font-size: $size-f!important;
            }
        }
        h2 {
            line-height: 1.25;
        }
        p {
            line-height: 1.4;
        }
    }
}