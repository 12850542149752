@font-face {
  font-family:'Chivo';
    src: url('../fonts/Chivo-Black.eot');
    src: url('../fonts/Chivo-Black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Chivo-Black.woff') format('woff'),
         url('../fonts/Chivo-Black.ttf') format('truetype'),
         url('../fonts/Chivo-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family:'Chivo';
    src: url('../fonts/Chivo-Bold.eot');
    src: url('../fonts/Chivo-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Chivo-Bold.woff') format('woff'),
         url('../fonts/Chivo-Bold.ttf') format('truetype'),
         url('../fonts/Chivo-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family:'Chivo';
    src: url('../fonts/Chivo-Regular.eot');
    src: url('../fonts/Chivo-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Chivo-Regular.woff') format('woff'),
         url('../fonts/Chivo-Regular.ttf') format('truetype'),
         url('../fonts/Chivo-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family:'Chivo';
    src: url('../fonts/Chivo-Light.eot');
    src: url('../fonts/Chivo-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Chivo-Light.woff') format('woff'),
         url('../fonts/Chivo-Light.ttf') format('truetype'),
         url('../fonts/Chivo-Light.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family:'Chivo';
    src: url('../fonts/Chivo-BlackItalic.eot');
    src: url('../fonts/Chivo-BlackItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Chivo-BlackItalic.woff') format('woff'),
         url('../fonts/Chivo-BlackItalic.ttf') format('truetype'),
         url('../fonts/Chivo-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family:'Chivo';
    src: url('../fonts/Chivo-BoldItalic.eot');
    src: url('../fonts/Chivo-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Chivo-BoldItalic.woff') format('woff'),
         url('../fonts/Chivo-BoldItalic.ttf') format('truetype'),
         url('../fonts/Chivo-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family:'Chivo';
    src: url('../fonts/Chivo-Italic.eot');
    src: url('../fonts/Chivo-Italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Chivo-Italic.woff') format('woff'),
         url('../fonts/Chivo-Italic.ttf') format('truetype'),
         url('../fonts/Chivo-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family:'Chivo';
    src: url('../fonts/Chivo-LightItalic.eot');
    src: url('../fonts/Chivo-LightItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Chivo-LightItalic.woff') format('woff'),
         url('../fonts/Chivo-LightItalic.ttf') format('truetype'),
         url('../fonts/Chivo-LightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}