.s-block {
  position:relative;
  margin-top:3em;
  &__title {
    @extend .all-caps;
    @include for(tablet) {
      position:absolute;
      left:0.5em;
      bottom:0;
      transform:rotate(270deg);
      transform-origin:left bottom 0;
    }
  }
  &__image {
    @extend .col-xs-12;
    @extend .col-sm-6;
    position:relative;
    img {
      width:100%;
      height:auto;
    }
  }
  &__heading {
    @extend .col-xs-11;
    @extend .col-sm-6;
    @extend .chunk-white-heading;
    font-size:$size-f;
    margin-top:-2em;
    margin-bottom:0;
    margin-left:0em;
    padding:$size-c;
    @include for(mobile-only) {
      float:right;
      margin-bottom:0;
      padding-bottom:$size-d;
    }
    @include for(tablet) {
      margin-left:-2em;
      margin-top:0.5em;
    }
    @include for(desktop) {
      font-size:$size-g;
    }
    background:$grey;
  }
  &__links {
    @extend .col-xs-11;
    @extend .col-sm-12;
    @extend .col-md-5;
    @include for(mobile-only) {
      float:right;
      padding:$size-e;
      padding-top:0;
      margin-top:-$size-d;
    }
    p {
      font-size:$size-e;
      @include for (mobile-only){
        margin-top: 0;
      }
      @include for(desktop) {
        margin-top:0;
      }
      a {
        font-weight:bold;
        &:after {
          content: "";
          background-image:url("../images/right-arrow.svg");
          background-repeat:no-repeat;
          background-size:contain;
          display:inline-block;
          width:40px;
          height:30px;
          vertical-align:middle;
          margin-left:5px;
        }
      }
    }
    ul {
      list-style:none;
      padding-left: 30px;
      li {
        text-indent:-13px;
        margin-bottom:0.3em;
        @extend .all-caps;
        color:$blue;
        &:before {
          content:"–";
          position:relative;
          left:-1em;
          top:-0.1em;
          color:$blue;
        }
      }
    }
  }
}
