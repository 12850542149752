.s-gallery {
  clear:both;
  position:relative;
  &__heading {
    @extend .caps-black-heading;
    margin-top:3em;
  }
  &__item {
    .image-filter {
      width:100%;
    }
    img {
      width:100%;
      height:auto;
    }
  }
  
  
  .owl-item {
    .image-filter {
      background-color:$black;
    }
    .active {
      
    }
  } 
  
  .owl-controls {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    
    .owl-nav {
      .owl-prev,
      .owl-next {
        @extend .text-hide;
        position:absolute;
        top:0;
        left:0;
        bottom:42px;
        width:99px;
        background-color:rgba($black, 0.5);
        background-image:url("../images/carousel-arrow-left.svg");
        background-repeat:no-repeat;
        background-position:center center;
        background-size:25px 100%;
      }
      .owl-next {
        right:0;
        left:auto;
        background-image:url("../images/carousel-arrow-right.svg");        
      }
    }
  
    .owl-dots {
      .owl-dot {
        display: inline-block;
        zoom: 1;
        span {
          width:12px;
          height:12px;
          margin:5px;
          background:$grey-darker;
          display:block;
          -webkit-backface-visibility:visible;
          transition: opacity 200ms ease;
          border-radius: 30px;
        }
        &.active,
        &:hover {
          span {
              background:$blue;
          }
        }
      }
    }
  
  }
  
}