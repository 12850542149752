.s-form {
  &__input {
    background-color:$grey;
    padding:0.5em 1em;
    color:$grey-darkest;
    background-image:none;
    border:1px solid $grey;
    border-radius:0;
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
    &--block {
      width:100%;
    }
  }

  &__button {
    @extend .s-button;
    @extend .s-button--yellow;
    padding:0.5em;
    background-image:none;
    border-radius:0;
    &--block {
      text-align:center;
      display:block;
      width:100%;
    }
  }
}
